import { http } from '@/core/api/';
const apiUrl = 'api/documents';

export default {
  //The documentFile param has the DocumentDTO squema
  Download(documentFile) {
    const byteChars = atob(documentFile.content);
    const byteNumbers = new Array(byteChars.length);
    for (let i = 0; i < byteChars.length; i++) {
      byteNumbers[i] = byteChars.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    let blob = new Blob([byteArray], {
      type: documentFile.contentType,
    });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = documentFile.filename;
    link.click();
  },
  downloadFile(documentFile) {
    const fr = new FileReader();
    fr.readAsDataURL(documentFile.content);
    const blob = new Blob([documentFile.content], {
      type: documentFile.contentType,
    });

    const objectURL = window.URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = objectURL;
    link.download = documentFile.filename;
    document.body.appendChild(link);
    link.click();
    link.remove();
  },
  async downloadFromServer(documentId) {
    await http
      .get(`${apiUrl}/downloaddocument?id=${documentId}`)
      .then((response) => {
        this.Download(response.data);
      });
  },
  async getDocumentTypes(codeType) {
    return await http.get(`${apiUrl}/documenttypes?codeType=${codeType}`);
  },
};
