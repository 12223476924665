import { http } from '@/core/api/';
import DocumentsService from '@/services/documents';

const apiUrl = 'api/report';
export default {
  /*
    model { regStudentId, evaluationDateId, isInitial }
  */
  async downloadEE06(model) {
    const { data: document } = await this.getEE06(model);
    DocumentsService.Download(document);
  },

  /*
    model { regStudentId, evaluationDateId, isInitial, placementCommitteeId }
  */
  async getEE06(model) {
    return await http.get(
      `${apiUrl}/EE06/${model.regStudentId}/${model.placementCommitteeId}`
    );
  },

  async downloadEE05(regStudentId, isInitial) {
    await http
      .get(`${apiUrl}/EE05/${regStudentId}/${isInitial}`)
      .then((data) => {
        DocumentsService.Download(data);
      });
  },

  async downloadVaccinationCertification(studentSieId) {
    await http
      .get(`${apiUrl}/vaccinationcertification/${studentSieId}`)
      .then((data) => {
        DocumentsService.Download(data);
      });
  },

  async downloadTransportation(peiId, analysisServiceId) {
    await http
      .get(`${apiUrl}/transportation/${peiId}/${analysisServiceId}`)
      .then(({ data }) => {
        DocumentsService.Download(data);
      });
  },

  async downloadTransportationAEE(peiId, analysisServiceId) {
    await http
      .get(`${apiUrl}/transportationaee/${peiId}/${analysisServiceId}`)
      .then(({ data }) => {
        DocumentsService.Download(data);
      });
  },
  async downloadTapRequestPurchaseService(params) {
    await http
      .get(`${apiUrl}/taprequest/purchaseservice`, { params })
      .then(({ data }) => DocumentsService.Download(data));
  },
  async downloadTapRequestRefund(params) {
    await http
      .get(`${apiUrl}/taprequest/refund`, { params })
      .then(({ data }) => DocumentsService.Download(data));
  },
  async downloadTapRequestDirectPayment(params) {
    await http
      .get(`${apiUrl}/taprequest/directpayment`, { params })
      .then(({ data }) => DocumentsService.Download(data));
  },
  async downloadStudentsByProgressAcademicYearReport(id) {
    const { data } = await http.get(
      `${apiUrl}/studentsbyprogressacademicyearreport/${id}`
    );
    DocumentsService.Download(data);
  },
  async getPeiResultSummaryDocument(peiId) {
    return http.get(`${apiUrl}/resultsummarydocument/${peiId}`);
  },
};
