import { Colors } from '@/components/common';

const disbursementRequestStatuses = {
  Nuevo: 1,
  Sometido: 2,
  DevueltoARevisión: 3,
  ProcesoDeRevisión: 4,
  SometidoRevisado: 5,
  PendienteDeAprobación: 6,
  Aprobado: 7,
  EnviadoAFinanzas: 8,
  Pagado: 9,
  Cancelado: 10,
  EnProcesoDePago: 11,
};

export const parseStateColorByDisbursementStatus = (statusId) => {
  switch (statusId) {
    case disbursementRequestStatuses.Nuevo:
      return Colors.GRAY;
    case disbursementRequestStatuses.Sometido:
      return Colors.YELLOW;
    case disbursementRequestStatuses.ProcesoDeRevisión:
    case disbursementRequestStatuses.PendienteDeAprobación:
    case disbursementRequestStatuses.EnProcesoDePago:
      return Colors.SWEETCORN;
    case disbursementRequestStatuses.DevueltoARevisión:
    case disbursementRequestStatuses.Cancelado:
      return Colors.BROWN;
    case disbursementRequestStatuses.SometidoRevisado:
      return Colors.VIOLET;
    case disbursementRequestStatuses.Aprobado:
    case disbursementRequestStatuses.Pagado:
      return Colors.CELADON;
    case disbursementRequestStatuses.EnviadoAFinanzas:
      return Colors.ORANGE_LIGHT_2;
    default: {
      return Colors.RED;
    }
  }
};

export default disbursementRequestStatuses;
