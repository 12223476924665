<template>
  <div class="rounded mx-1">
    <div class="rounded mx-2">
      <custom-result-not-found v-if="model.data.length == 0" />
      <div
        v-for="(visitItem, visitIndex) in model.data"
        :key="`visit-details_${visitIndex}`"
        class="row mx-0 mx-md-4 mx-sm-0 p-3 bg-gray-1 mb-3"
      >
        <div class="col-12 col-md-2 pt-2">
          <div class="font-14 text-gray">
            Fecha:
          </div>
          <div class="regular color-black">
            {{ formatter(visitItem.visitDate) | empty }}
          </div>
        </div>
        <div class="col-12 col-md-4 pt-2">
          <div class="font-14 text-gray">
            Horario:
          </div>
          <div class="regular color-black">
            {{ visitItem.completeTime | empty }}
          </div>
        </div>
        <div class="col-12 col-md-3 pt-2">
          <div class="font-14 text-gray">
            Tipo de Visita:
          </div>
          <div class="regular color-black">
            {{ visitItem.visitTypeName | empty }}
          </div>
        </div>
        <div class="col-12 col-md-3 pt-2">
          <div class="font-14 text-gray">
            Especialista:
          </div>
          <div class="regular color-black">
            {{ visitItem.visitSpecialistName | empty }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import CustomResultNotFound from 'custom-result-not-found';

export default {
  name: 'VisitDetails',
  components: {
    CustomResultNotFound,
  },
  props: {
    visits: {
      type: Object,
      default: null,
    },
  },
  computed: {
    model() {
      return this.visits;
    },
  },
  methods: {
    formatter(date) {
      return (
        date &&
        moment(date)
          .locale('es')
          .format('DD/MMM/YYYY')
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.bg-gray-1 {
  background-color: #F4F4F4;
}
</style>

