<template>
  <div class="row">
    <div class="col-12 py-1">
      <slot :active="active" :toggle="toggle" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'DynamicFormListItem',
  data: () => ({ active: false }),
  methods: {
    toggle() {
      this.active = !this.active;
    },
  },
};
</script>
