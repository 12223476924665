import { http } from '@/core/api/';

const URL = 'api/taprequest';

export const initFilters = () => http.get(`${URL}/initFilters`);

export const search = (params) => http.get(`${URL}/search`, { params });

export const getHistory = (requestNumber) => {
  return http.get(`${URL}/gethistory/${requestNumber}`);
};
export const getVisitDetails = (params) =>
  http.get(`${URL}/visitdetails`, { params });

export const getServiceProposalDocument = (requestId) =>
  http.get(`${URL}/document/serviceproposal/${requestId}`);
