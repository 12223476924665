<template>
  <div class="rounded p-2">
    <div class="rounded p-3">
      <div class="row">
        <div class="col-12 col-md-3 pt-2">
          <div class="font-14 text-gray">
            Centro de Servicio de Educación Especial:
          </div>
          <div class="regular color-black">
            {{ model.centerName | empty }}
          </div>
        </div>
        <div class="col-12 col-md-3 pt-2">
          <div class="font-14 text-gray">
            Determinación:
          </div>
          <div class="regular color-black">
            {{ model.determinationTypeName | empty }}
          </div>
        </div>

        <div class="col-12 col-md-3 pt-2">
          <div class="font-14 text-gray">
            Municipio de Residencia:
          </div>
          <div class="regular color-black">
            {{ model.location.municipality | empty }}
          </div>
        </div>

        <div class="col-12 col-md-3 pt-2">
          <div class="font-14 text-gray">
            Escuela:
          </div>
          <div class="regular color-black">
            {{ formatSchool(model.location.school) }}
          </div>
        </div>
        <div v-if="!isRefund" class="col-12 col-md-3 pt-2">
          <div class="regular text-gray">
            Fecha de Comienzo:
          </div>
          <div class="regular color-black">
            {{ formatter(model.assignedData.assignmentStartDate) | empty }}
          </div>
        </div>
        <div v-if="!isRefund" class="col-12 col-md-3 pt-4">
          <div class="font-14 text-gray">
            Proveedor:
          </div>
          <div class="regular color-black">
            {{
              model.assignedData.assignedProvider &&
                model.assignedData.assignedProvider.name | empty
            }}
          </div>
        </div>
        <div v-if="!isRefund" class="col-12 col-md-3 pt-4">
          <div class="font-14 text-gray">
            Lugar de Servicio:
          </div>
          <div class="regular color-black">
            {{
              model.assignedData.placement &&
                model.assignedData.placement.name | empty
            }}
          </div>
        </div>
        <div v-if="!isRefund" class="col-12 col-md-3 pt-4">
          <div class="font-14 text-gray">
            Maestro:
          </div>
          <div class="regular color-black">
            {{
              model.assignedData.teacher &&
                model.assignedData.teacher.name | empty
            }}
          </div>
        </div>
        <div v-if="!isRefund" class="col-12 col-md-3 pt-4">
          <div class="font-14 text-gray">
            Asistente de Servicio:
          </div>
          <div class="regular color-black">
            {{
              model.assignedData.assistant &&
                model.assignedData.assistant.name | empty
            }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import { determinationTypes } from '../constants/tapRequestStatuses';
export default {
  name: 'GeneralInformation',
  props: {
    existingData: {
      type: Object,
      default: null,
    },
  },
  computed: {
    model() {
      return this.existingData;
    },
    isRefund() {
      return this.model.determinationType == determinationTypes.Reembolso;
    },
  },
  methods: {
    formatter(date) {
      return (
        date &&
        moment(date)
          .locale('es')
          .format('DD/MMM/YYYY')
      );
    },
    formatSchool(school) {
      return school && school.code + ' - ' + school.name;
    },
  },
};
</script>
