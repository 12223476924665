// Lista de colores
const colors = Object.freeze({
  GREEN: 'green',
  GREEN_LIGHT: 'green-light',
  BROWN: 'brown',
  YELLOW: 'yellow',
  PINK: 'pink',
  VIOLET: 'violet',
  CREAM: 'cream',  
  PEACH: 'peach',
  ORANGE: 'primary-2',
  RED: 'primary-4',
  GRAY: 'gray-2',
  SWEETCORN: 'secondary-23',
  CELADON: 'celadon',
  ORANGE_LIGHT_2: 'orange-light-2',
});

export const Colors = colors;
export * from './DynamicFormList';







