import { http } from '@/core/api/';

const URL = 'api/disbursementrequest';

export const getRequestDetails = (requestId) =>
  http.get(`${URL}/details/${requestId}`);

export const getRequestDetailsByTapRequest = (tapRequestId) =>
  http.get(`${URL}/taprequestdetails/${tapRequestId}`);  

export const checkTapRequestDisbursement = (tapRequestId) =>
  http.post(`${URL}/checktaprequest/${tapRequestId}`);

export const saveRequest = (parameters) => http.post(`${URL}/save`, parameters);

export const validateSsn = (ssn) => 
  http.get(`${URL}/validatessn/${ssn}`);  

export const getDisbursementRequestHistory = (params) =>
  http.get(`${URL}/history`, { params });

export const uploadOrUpdateFile = (payload) =>
  http.post(`${URL}/uploadorupdatedisbursementdocument`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const updateRequestStatus = (parameters) =>
  http.put(`${URL}/updaterequeststatus`, parameters);

export const savePartialRequest = (parameters) =>
  http.post(`${URL}/save/partial`, parameters, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const submitRequest = (payload) =>
  http.post(`${URL}/submit`, payload, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

export const getSIFDERegistrationDocument = () =>
  http.get(`${URL}/sifderegistrydocument`);

export const getPaymentTabInfo = (billId) =>
  http.get(`${URL}/payment/${billId}`);

export const getServicesByPeriod = (studentSieId, month, year) =>
  http.get(`${URL}/getservicesbyperiod/${studentSieId}/${month}/${year}`);

  export const getBlockedPeriods = (studentSieId, requestId, tapRequestId) =>
  http.get(`${URL}/getblockedperiods/${studentSieId}/${requestId}/${tapRequestId}`);
