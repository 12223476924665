export const tapRequestStatuses = {
  Nuevo: 1,
  Sometido: 2,
  ProcesoConsulta: 3,
  DevueltoEscuela: 4,
  ProcesoAnálisisCSEE: 5,
  DevueltoFacilitadorDocente: 6,
  RecomendadoEvaluaciónCSEE: 7,
  RecomendadoEvaluaciónNivelCentral: 10,
  ProcesoAnálisisNivelCentral: 11,
  DevueltoDirectorCSEE: 12,
  DevueltoNivelCentral: 14,
  Aprobado: 15,
  NoAprobado: 16,
  PendienteRevisionEscuela: 17,
  SometidoRevisado: 18,
  RecomendadoSecretario: 19,
  NoRecomendado: 20,
  Notificado: 21,
  ProcesoAnálisisdelSecretario: 22,
  Asignado: 23,
  Enmendado: 24,
  ProcesoEnmienda: 25,
  NoVigenteBaja: 26,
  NoVigenteEgreso: 27,
  NoVigenteTraslado: 28,
};

export const determinationTypes = {
  CompraServicio: 1,
  Reembolso: 2,
  PagoDirecto: 3,
};