<template>
  <div>
    <page-header
      :actions="headerActionsConfig"
      :page="{
        icon: 'fas fa-map-marked-alt',
        title: 'Historial de Consulta de Ubicación',
        routes: ['Inicio', 'Historial de Consulta de Ubicación', 'Búsqueda'],
      }"
    />
    <filter-manager
      id="history-location-filter-manager"
      ref="filterComponent"
      v-model="filterModel"
      class="mb-4 px-4"
      :display-advanced="false"
      :filters="filtersConfig"
      search-text=""
    >
      <div class="col-12 col-md-2 pt-1">
        <action-button
          class="search col"
          i="fas fa-search"
          text="Buscar"
          variant="success"
          @click="onSearchHandler"
        />
      </div>
    </filter-manager>
    <custom-separator class="my-4" text="Resultado de Consulta" />
    <custom-result-not-found v-if="results.length === 0" />
    <history-result
      v-for="(row, idx) of results"
      :key="`result-${idx}`"
      :active-row="activeRow"
      :data="row"
      :index="idx"
      :ssn-active-row="ssnActiveRow"
      @on-open-close="openCloseResultHandler"
      @on-ssn-open-close="openCloseSsnHandler"
    />
    <pagination
      v-if="pagination.totalItemCount > pagination.pageSize"
      :data="pagination"
      show-text
      @pagination-go-page="goToPage"
    />
  </div>
</template>

<script>
import PageHeader from 'PageHeader';
import Pagination from 'pagination';
import ActionButton from 'ActionButton';
import FilterManager from 'FilterManager';
import CustomSeparator from 'custom-separator';
import CustomResultNotFound from 'custom-result-not-found';
import HistoryResult from './components/HistoryResult.vue';
import {
  initFilters as _initFilters,
  search as _search,
} from '@/services/api/historyLocation.api';

import storeMixin from '@quisit/mipe-utils/mixins/storeGenerator';

const MODULE_NAME = 'HistoryLocationModule';

export default {
  name: 'HistoryLocation',
  components: {
    PageHeader,
    FilterManager,
    CustomSeparator,
    CustomResultNotFound,
    Pagination,
    ActionButton,
    HistoryResult,
  },
  moduleName: MODULE_NAME,
  mixins: [storeMixin],
  data() {
    return {
      pagination: {
        currentPage: 1,
        totalItemCount: 0,
        pageCount: 1,
        count: 0,
        pageSize: 10,
      },
      filterModel: {
        requestType: null,
        determination: null,
        status: null,
        requestNumber: null,
      },
      filterOptions: {
        requestTypes: [],
        determinations: [],
        statuses: [],
      },
      results: [],
      activeRow: null,
      ssnActiveRow: null,
    };
  },
  async mounted() {
    await this.initFilters();

    if (this.$route.params.from == 'reinbursement') {
      this.clearData();
      this.onSearchHandler();
    } else if (this.$route.params.from == 'menu') {
      this.clearData();
    }
  },
  destroyed() {
    this.persistData();
  },
  computed: {
    headerActionsConfig() {
      return [
        {
          text: 'Regresar',
          icon: 'fas fa-arrow-circle-left fa-fw',
          variant: 'outline-success',
          click: () => this.$router.push({ name: 'home' }),
        },
      ];
    },
    filtersConfig() {
      return [
        {
          name: 'requestType',
          component: 'CustomDropDown',
          options: this.filterOptions.requestTypes,
          placeholder: 'Tipo de Solicitud',
          fieldName: 'name',
          fieldKey: 'id',
          class: 'col-12 col',
          initialValue: this.filterModel.requestType,
        },
        {
          name: 'determination',
          component: 'CustomDropDown',
          options: this.filterOptions.determinations,
          placeholder: 'Determinación',
          fieldName: 'name',
          fieldKey: 'id',
          class: 'col-12 col',
          initialValue: this.filterModel.determination,
        },
        {
          name: 'status',
          component: 'CustomDropDown',
          options: this.filterOptions.statuses,
          placeholder: 'Estatus',
          fieldName: 'name',
          fieldKey: 'id',
          class: 'col-12 col',
          initialValue: this.filterModel.status,
        },
        {
          name: 'requestNumber',
          component: 'CustomInput',
          icon: 'fa fa-search color-orange',
          leftIcon: true,
          placeholder: 'Escriba Número de Solicitud',
          class: 'col-12 col',
          initialValue: this.filterModel.requestNumber,
        },
      ];
    },
    selectedStudentId() {
      return +this.$route.params.studentSieId;
    },
  },
  methods: {
    async initFilters() {
      const { data } = await _initFilters();

      if (data) {
        this.filterOptions = {
          ...this.filterOptions,
          ...data,
        };
      }
    },
    async goToPage(page) {
      const payload = this.fillPayload(page);
      await this.search(payload);
    },
    fillPayload(page) {
      const payload = {
        studentSieId: this.selectedStudentId,
        statusId: this.filterModel.status?.id,
        requestType: this.filterModel.requestType?.id,
        determinationType: this.filterModel.determination?.id,
        filter: this.filterModel.requestNumber,
        pageIndex: page ? page : 1,
        pageSize: this.pagination.pageSize,
      };
      return payload;
    },
    async onSearchHandler() {
      const pageIndex = 1;

      const payload = this.fillPayload(pageIndex);
      this.search(payload);
    },
    async search(payload) {
      const { data: res } = await _search(payload);
      this.results = [...res.data];
      this.pagination = {
        ...this.pagination,
        totalItemCount: res.totalItemCount,
        pageCount: res.pageCount,
        currentPage: res.currentPage,
        count: res.count,
      };
    },
    openCloseResultHandler(index) {
      this.activeRow = index;
    },
    openCloseSsnHandler(index) {
      this.ssnActiveRow = index;
    },
    clearData() {
      this.filterModel = {
        requestType: null,
        determination: null,
        status: null,
        requestNumber: null,
      };
      this.results = [];
    },
  },
};
</script>
