<template>
  <div class="row">
    <div class="col-12">
      <div v-for="(item, index) in items" :key="`item_${index}`" class="py-1">
        <slot :index="index" :item="item" name="items" />
      </div>
    </div>
    <div :class="['col-12', { 'order-first': formOnTop }]">
      <div v-if="active_sync" class="py-1">
        <slot name="form" :toggle="toggle" />
      </div>
      <div v-else class="py-1">
        <slot :active="active_sync" name="form-toggler" :toggle="toggle" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'DynamicFormList',
  props: {
    items: { type: Array, required: true, default: () => [] },
    active: { type: Boolean, default: null },
    formOnTop: { type: Boolean, default: false },
  },
  data: () => ({ active_fallback: false }),
  computed: {
    active_sync: {
      get() {
        return this.active === null ? this.active_fallback : this.active;
      },
      set(value) {
        if (this.active === null) {
          this.active_fallback = value;
        } else {
          this.$emit('update:active', value);
        }
      },
    },
  },
  methods: {
    toggle() {
      this.active_sync = !this.active_sync;
    },
  },
};
</script>
