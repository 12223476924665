<template>
  <div>
    <custom-callout
      class-main="mt-3 rounded"
      :controlled="true"
      :opened="activeRow === index"
      @click:open="onOpenClose"
    >
      <div class="row py-2 mr-md-0 align-items-center pr-5">
        <div class="row col-12">
          <div
            class="
              col-12 col-md-2
              px-0
              py-2
              border-right border-right--variant
              d-flex
              justify-content-center
            "
          >
            <div class="col-12 row d-flex font-weight-bold font-17 px-0">
              <span v-if="data.requestNumber" class="row col-12">
                {{ data.requestNumber }}
              </span>
              <div class="font-weight-normal color-gray font-14">
                <i
                  class="fas fa-circle mr-2 font-12"
                  :style="`color: ${data.status.color}`"
                />
                <span class="font-16 font-weight-bold">
                  {{ statusName }}
                </span>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-9 row mx-0">
            <div class="col-12 col-md mt-3 mt-md-0 pl-4">
              <span class="data-title d-block">Año Académico:</span>
              {{ data.academicYear | empty }}
            </div>
            <div class="col-12 col-md mt-3 mt-md-0">
              <span class="data-title d-block">Tipo de Solicitud:</span>
              {{ data.requestType | empty }}
            </div>
            <div class="col-12 col-md mt-3 mt-md-0">
              <span class="data-title d-block">Solicitante:</span>
              {{ data.requester.name | empty }}
            </div>
            <div class="col-12 col-md mt-3 mt-md-0">
              <span class="data-title d-block">Puesto de Solicitante:</span>
              {{ data.requester.position | empty }}
            </div>
            <div
              v-if="data.assignmentStartDate"
              class="col-12 col-md mt-3 mt-md-0"
            >
              <span class="data-title d-block">Vigencia:</span>
              <p class="mb-0">
                {{ data.assignmentStartDate | formatDate }} -
                {{ data.assignmentEndDate | formatDate }}
              </p>
            </div>
          </div>
          <div class="col-12 col-md-1 mt-3 mt-md-0">
            <drop-down :items="actionItems" />
          </div>
        </div>
        <div>
          <custom-error
            v-if="isNotApproved"
            :is-alert-mode="true"
            :message="
              `Su consulta no ha sido aprobada, favor comunicarse con el Centro de Servicio de Educación Especial de ${data.location.school.name}`
            "
          />
        </div>
      </div>
      <template #edition>
        <div class="additional-container">
          <div class="p2 mt-1">
            <div class="bg-gray p-2">
              <div class="bg-white p-3 rounded pl-c-xl">
                <custom-tab
                  content-class="mt-3"
                  :default-active-tab="activeTab"
                  nav-class="nav-vue-underline"
                  :no-nav-style="true"
                >
                  <custom-tab-item
                    class="mt-2 regular"
                    title="Información Adicional"
                    @click="aditionalInformation()"
                  >
                    <general-information :existing-data="data" />
                  </custom-tab-item>
                  <custom-tab-item
                    v-if="isServicePurchase"
                    key="2"
                    :active="activeTab === 1"
                    class="mt-4 regular"
                    lazy
                    title="Visita de Servicio"
                    @click="viewVisitDetailHandler(data)"
                  >
                    <visit-details :visits="requestVisits" />
                    <div
                      v-if="
                        requestVisits &&
                          requestVisits.data &&
                          requestVisits.pagination.totalItemCount >
                            getVisitDetailsPaginationSize
                      "
                      class="mx-3"
                    >
                      <pagination
                        :data="requestVisits.pagination"
                        parent-id="visit-details"
                        show-text
                        @pagination-go-page="
                          goToPageHandlerVisitDetails($event, data)
                        "
                      />
                    </div>
                  </custom-tab-item>
                  <custom-tab-item
                    key="5"
                    :active="activeTab === 5"
                    class="mt-2 regular"
                    title="Historial De Consulta"
                    @click="requestHistory(5)"
                  >
                    <div v-for="(item, idx) in historyRequestList" :key="idx">
                      <custom-callout
                        class-main="mt-3 rounded"
                        :opened="item.isOpen"
                        :controlled="true"
                        :has-toggle="false"
                        @click:open="item.isOpen = false"
                      >
                        <div class="row col-12">
                          <div
                            class="
                              col-12 col-md-2
                              px-0
                              py-2
                              border-right border-right--variant
                              d-flex
                              justify-content-center
                            "
                          >
                            <div
                              class="col-12 row d-flex font-weight-bold font-17 px-0"
                            >
                              <span
                                v-if="item.requestNumber"
                                class="row col-12"
                              >
                                {{ item.requestNumber }}
                              </span>
                              <div
                                class="font-weight-normal color-gray font-14"
                              >
                                <i
                                  class="fas fa-circle mr-2 font-12"
                                  :style="`color: ${item.status.color}`"
                                />
                                <span class="font-16 font-weight-bold">
                                  {{ item.status.category.name }}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-md-9 row mx-0">
                            <div class="col-12 col-md mt-3 mt-md-0">
                              <span class="data-title d-block">
                                Tipo de Solicitud:
                              </span>
                              {{ item.requestType | empty }}
                            </div>
                            <div class="col-12 col-md mt-3 mt-md-0">
                              <span class="data-title d-block">
                                Solicitante:
                              </span>
                              {{ item.requester.name | empty }}
                            </div>
                            <div class="col-12 col-md mt-3 mt-md-0">
                              <span class="data-title d-block">
                                Puesto de Solicitante:
                              </span>
                              {{ item.requester.position | empty }}
                            </div>
                            <div
                              v-if="item.assignmentStartDate"
                              class="col-12 col-md mt-3 mt-md-0"
                            >
                              <span class="data-title d-block">Vigencia:</span>
                              <p class="mb-0">
                                {{ item.assignmentStartDate | formatDate }} -
                                {{ item.assignmentEndDate | formatDate }}
                              </p>
                            </div>
                          </div>
                          <div class="col-12 col-md-1 mt-3 mt-md-0">
                            <drop-down
                              :data-item="{ item }"
                              :items="actionItemsRequest(item)"
                            />
                          </div>
                        </div>
                      </custom-callout>
                      <request-reimbursement
                        v-if="item.isReimbursementActive"
                        :academic-year-id="item.academicYearId"
                        :tap-request-id="item.id"
                        @on-continue="onContinue"
                        @on-cancel="item.isReimbursementActive = false"
                      />
                    </div>
                  </custom-tab-item>
                  <custom-tab-item
                    v-if="canShowReinbursement && hasHistory"
                    key="4"
                    :active="activeTab === 4"
                    class="mt-2 regular"
                    title="Historial de Reembolso"
                    @click="showHistory(data)"
                  >
                    <custom-result-not-found v-if="historyList.length === 0" />
                    <time-line v-else class="mt-4 col-11 mx-auto px-0">
                      <time-line-item
                        v-for="(item, idx) in historyList"
                        :key="`item-row-${idx}`"
                        class="custom-time-line rounded"
                      >
                        <div class="row">
                          <div class="col-12 col-md-3 flex-column d-flex">
                            <label class="mb-0 px-2 color-gray mb-1">
                              Número de Solicitud:
                            </label>
                            <b class="px-2">{{ item.requestNumber }}</b>
                          </div>
                          <div class="col-12 col-md-3 flex-column d-flex">
                            <label class="mb-0 px-2 color-gray mb-1">
                              Estatus:
                            </label>
                            <div class="font-weight-normal font-14 px-2">
                              <i
                                class="fas fa-circle mr-2 font-12"
                                :class="
                                  `color-${getDisbursementStatusColor(
                                    item.status.id
                                  )}`
                                "
                              />
                              <span class="font-16">
                                {{ item.status.name }}
                              </span>
                            </div>
                          </div>
                          <div
                            class="
                              col-12 col-md-4
                              flex-column
                              d-flex
                              color-gray
                            "
                          >
                            <label class="mb-0 px-2 d-flex mb-1">
                              Periodo Reembolsado:
                            </label>

                            <div v-if="item.periods.length > 0">
                              <div class="px-2">
                                <span
                                  v-for="(period, idx) in item.periods"
                                  :key="idx"
                                  class="px-0"
                                >
                                  {{ period.periodName }}

                                  <span
                                    v-if="
                                      idx !=
                                        Object.keys(item.periods).length - 1
                                    "
                                    class="spam-separator"
                                  >
                                    ,
                                  </span>
                                </span>
                              </div>
                            </div>
                            <p v-else class="px-2">-</p>
                          </div>
                          <div
                            class="
                              col-12 col-md-2
                              d-flex
                              color-gray
                              justify-content-end
                              pt-3
                              pb-3
                            "
                          >
                            <action-button
                              i="fas fa-file-search"
                              variant="outline-success"
                              @click="goToDetail(item.requestId)"
                            >
                              Ver detalle
                            </action-button>
                          </div>
                        </div>
                        <template #icon>
                          <img
                            alt=""
                            class="radius color-green fa-2x"
                            src="@/assets/img/circle-dollar-to-slot.svg"
                          />
                        </template>
                        <template #date>
                          <div class="date-container">
                            <div
                              class="
                                color-black
                                font-16 font-weight-bold
                                fulldate
                              "
                            >
                              {{ item.createdDate | moment('DD/MMM/YYYY') }}
                              <small>
                                {{ item.createdDate | moment('hh:mm A') }}
                              </small>
                            </div>
                          </div>
                        </template>
                      </time-line-item>
                    </time-line>
                    <div
                      v-if="totalCountHistory > 3 && count < totalCountHistory"
                      class="
                        d-flex
                        justify-content-center
                        align-items-center
                        mt-3
                      "
                    >
                      <action-button
                        class="btn-see-more"
                        i="fas fa-chevron-down"
                        text="Ver más"
                        variant="outline-success rounded-pill px-5"
                        @click="loadMore(data)"
                      />
                    </div>
                  </custom-tab-item>
                </custom-tab>
              </div>
            </div>
          </div>
        </div>
      </template>
    </custom-callout>
    <request-reimbursement
      v-if="ssnActiveRow === index"
      :academic-year-id="data.academicYearId"
      :tap-request-id="data.id"
      @on-continue="onContinue"
      @on-cancel="$emit('on-ssn-open-close', null)"
    />
  </div>
</template>
<script>
import CustomCallout from 'custom-callout';
import DropDown from 'dropdown';
import CustomError from 'custom-error';
import CustomTab from 'custom-tabs/src/CustomTab';
import CustomTabItem from 'custom-tabs/src/CustomTabItem';
import GeneralInformation from './GeneralInformation';
import VisitDetails from './VisitDetails';
import RequestReimbursement from './RequestReimbursement';
import { TimeLine, TimeLineItem } from '@quisit/mipe-timeline';
import {
  getVisitDetails,
  getServiceProposalDocument,
} from '@/services/api/historyLocation.api';
import {
  getDisbursementRequestHistory,
  checkTapRequestDisbursement,
} from '@/services/api/disbursement.api.js';
import Pagination from 'pagination';
import documentService from '@/services/documents';
import ReportService from '@/services/report';
import alertMixin from 'utils/mixins/alert.mixin';
import {
  tapRequestStatuses,
  determinationTypes,
} from '../constants/tapRequestStatuses';
import requestTypes from '../constants/requestTypes';
import ActionButton from 'ActionButton';
import CustomResultNotFound from 'custom-result-not-found';
//utils
import { parseStateColorByDisbursementStatus } from '@/utils/constants/student/disbursementRequestStatuses';
import { getHistory as _getHistory } from '@/services/api/historyLocation.api';
const TabIdx = {
  GeneralInformation: 0,
  VisitDetailsTab: 1,
};

const INCREMENT_PAGE_SIZE = 3;
const DEFAULT_PAGE_SIZE = 3;
export default {
  name: 'HistoryResult',
  mixins: [alertMixin],
  components: {
    CustomCallout,
    DropDown,
    CustomError,
    CustomTab,
    CustomTabItem,
    GeneralInformation,
    VisitDetails,
    RequestReimbursement,
    TimeLine,
    TimeLineItem,
    Pagination,
    ActionButton,
    CustomResultNotFound,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    activeRow: {
      type: Number,
      required: true,
    },
    ssnActiveRow: {
      type: Number,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      activeTab: 0,
      historyList: [],
      count: 0,
      //historial dentro de historial de consulta
      historyListInHry: [],
      countInHistory: 0,
      pageSize: DEFAULT_PAGE_SIZE,
      pageSizeHistory: DEFAULT_PAGE_SIZE,
      totalCountHistory: null,
      totalCountHistoryInHry: null,
      requestVisits: {
        data: [],
        pagination: {},
      },
      openHistory: false,
      historyRequestList: [],
    };
  },
  computed: {
    getVisitDetailsPaginationSize() {
      return 5;
    },
    statusName() {
      return this.data?.status?.category?.name;
    },
    actionItems() {
      return [
        {
          label: 'Descargar Aprobación por Pago Directo',
          icon: 'fas fa-file-download color-green',
          click: () => this.downloadDirectPaymentDocument(),
          visible: this.isApprovedDirectPayment,
        },
        {
          label: 'Descargar Aprobación por Reembolso',
          icon: 'fas fa-file-download color-green',
          click: () => this.downloadRefundDocument(),
          visible: this.isApprovedRefund,
        },
        {
          label: 'Descargar Aprobación por Compra de Servicios',
          icon: 'fas fa-file-download color-green',
          click: () => this.downloadPurchaseServiceDocument(),
          visible: this.isApprovedPurchaseService,
        },
        {
          label: 'Descargar Propuesta',
          icon: 'fas fa-file-download color-green',
          click: () => this.downloadProposalDocument(),
          visible: !this.isConversion,
        },
        {
          label: 'Historial de Consulta',
          icon: 'fas fa-clock color-green',
          visible: true,
          click: () => this.requestHistory(5),
        },
        {
          label: 'Solicitar Reembolso',
          icon: 'fas fa-donate color-green',
          visible: this.canShowReinbursement,
          click: () => this.checkRequestDisbursements(),
        },
        {
          label: 'Historial de Reembolso',
          icon: 'fas fa-clock color-green',
          visible: this.canShowReinbursement && this.hasHistory,
          click: () => this.onOpenClose(4),
        },
      ];
    },
    hasHistory() {
      return this.data.disbursementRequestsCount != 0;
    },
    isApproved() {
      return this.data?.status.id === tapRequestStatuses.Aprobado;
    },
    isAssigned() {
      return this.data?.status.id === tapRequestStatuses.Asignado;
    },
    isNotified() {
      return this.data?.status.id === tapRequestStatuses.Notificado;
    },
    canShowReinbursement() {
      return this.data?.determinationType === determinationTypes.Reembolso;
    },
    isApprovedRefund() {
      return (
        (this.isNotified || this.isAssigned || this.isApproved) &&
        this.data?.determinationType === determinationTypes.Reembolso
      );
    },
    isApprovedDirectPayment() {
      return (
        (this.isNotified || this.isAssigned || this.isApproved) &&
        this.data?.determinationType === determinationTypes.PagoDirecto
      );
    },
    isApprovedPurchaseService() {
      return (
        (this.isNotified || this.isAssigned || this.isApproved) &&
        this.data?.determinationType === determinationTypes.CompraServicio
      );
    },
    isNotApproved() {
      return this.data?.status.id === tapRequestStatuses.NoAprobado;
    },
    isSubmit() {
      return this.data?.status.id === tapRequestStatuses.Sometido;
    },
    incrementSize() {
      return this.historyList.length + INCREMENT_PAGE_SIZE;
    },
    isServicePurchase() {
      return this.data?.determinationType === determinationTypes.CompraServicio;
    },
    isConversion() {
      return this.data?.requestTypeId === requestTypes.Conversion;
    },
  },
  async mounted() {
    this.$emit('on-open-close', null);
  },
  methods: {
    onContinue(result) {
      this.$emit('on-ssn-open-close', null);
      if (result.ssnValid) {
        this.$router.push({
          name: 'student-reinbursement',
          params: {
            tapRequestId: this.data.id,
            requestId: 0,
            ssn: result.ssn,
          },
        });
      } else {
        this.ShowToast(
          'Alerta',
          'El número de seguro social no se encontró en en el Sistema de Información Financiera del Departamento de Educación (SIFDE).',
          'error'
        );
      }
    },
    async onOpenClose(tabIdx) {
      this.$emit('on-ssn-open-close', null);
      this.activeTab = tabIdx ?? TabIdx.GeneralInformation;
      this.reset();
      if (tabIdx == 4) {
        this.$emit('on-open-close', this.index);
        await this.showHistory(this.data);
      }
      if (!tabIdx) {
        this.$emit(
          'on-open-close',
          this.index === this.activeRow ? null : this.index
        );
      }
    },
    async loadMore(item) {
      this.pageSize = this.incrementSize;
      await this.showHistory(item);
    },
    async loadMoreInHistory(item) {
      this.pageSizeHistory = this.historyListInHry.length + INCREMENT_PAGE_SIZE;
      await this.showHistory(item, true);
    },
    aditionalInformation() {
      this.activeTab = TabIdx.aditionalInformation;
      this.reset();
    },
    reset() {
      this.historyList = [];
    },
    async downloadPurchaseServiceDocument() {
      const params = { requestId: this.data.id };
      await ReportService.downloadTapRequestPurchaseService(
        params
      ).catch((err) =>
        this.ShowToast(
          'Alerta',
          err.response.data.message || err.message,
          'error'
        )
      );
    },
    async downloadDirectPaymentDocument() {
      const params = { requestId: this.data.id };
      await ReportService.downloadTapRequestDirectPayment(params).catch((err) =>
        this.ShowToast(
          'Alerta',
          err.response.data.message || err.message,
          'error'
        )
      );
    },
    async downloadRefundDocument() {
      const params = { requestId: this.data.id };
      await ReportService.downloadTapRequestRefund(params).catch((err) =>
        this.ShowToast(
          'Alerta',
          err.response.data.message || err.message,
          'error'
        )
      );
    },
    async downloadRefundDocumentFromHistory(id) {
      const params = { requestId: id };
      await ReportService.downloadTapRequestRefund(params).catch((err) =>
        this.ShowToast(
          'Alerta',
          err.response.data.message || err.message,
          'error'
        )
      );
    },
    onOpenCloseHistory(item) {
      item.isOpen = false;
    },
    async downloadProposalDocument() {
      const { data: documentResult } = await getServiceProposalDocument(
        this.data.id
      ).catch((err) => {
        this.ShowToast(
          'Alerta',
          err.response.data.message || err.message,
          'error'
        );
      });
      if (documentResult) {
        documentService.Download(documentResult);
      }
    },
    async downloadProposalDocumentFromHistory(id) {
      const { data: documentResult } = await getServiceProposalDocument(
        id
      ).catch((err) => {
        this.ShowToast(
          'Alerta',
          err.response.data.message || err.message,
          'error'
        );
      });
      if (documentResult) {
        documentService.Download(documentResult);
      }
    },
    async viewVisitDetailHandler(item) {
      await this.getVisitDetailList({
        tapRequestId: item.id,
        pageIndex: 1,
        pageSize: this.getVisitDetailsPaginationSize,
      });

      this.activeTab = TabIdx.VisitDetailsTab;
      this.reset();
    },
    goToDetail(requestId) {
      this.$router.push({
        name: 'student-reinbursement',
        params: {
          tapRequestId: this.data.id,
          requestId: requestId,
        },
      });
    },
    async showHistory(item, isFromHistory = false) {
      const { data: res } = await getDisbursementRequestHistory({
        tapRequestId: item.assignedData.tapRequestId,
        pageSize: !isFromHistory ? this.pageSize : this.pageSizeHistory,
      });
      if (!isFromHistory) {
        this.historyList = res.data;
        this.totalCountHistory = res.totalItemCount;
        this.count = res.count;
        return;
      }
      this.historyListInHry = res.data;
      this.totalCountHistoryInHry = res.totalItemCount;
      this.countInHistory = res.count;
    },
    async showHistoryRequest(requestNumber) {
      return await _getHistory(requestNumber);
    },
    actionItemsRequest(item) {
      return [
        {
          label: 'Descargar Aprobación por Reembolso',
          icon: 'fas fa-file-download color-green',
          click: () => this.downloadRefundDocumentFromHistory(item.id),
          visible: this.isApprovedRefund,
        },
        {
          label: 'Descargar Propuesta',
          icon: 'fas fa-file-download color-green',
          click: () => this.downloadProposalDocumentFromHistory(item.id),
          visible: !this.isConversion,
        },
      ];
    },
    async getVisitDetailList(payload) {
      const { data: visitResult } = await getVisitDetails(payload);
      this.requestVisits = {
        data: visitResult.data,
        pagination: {
          count: visitResult.count,
          currentPage: visitResult.currentPage,
          pageCount: visitResult.pageCount,
          totalItemCount: visitResult.totalItemCount,
        },
      };
      await this.$forceUpdate();
    },
    goToPageHandlerVisitDetails(page, item) {
      this.getVisitDetailList({
        tapRequestId: item.id,
        pageIndex: page,
        pageSize: this.getVisitDetailsPaginationSize,
      });
    },
    async checkRequestDisbursements() {
      this.$emit('on-open-close', null);
      await checkTapRequestDisbursement(this.data.id)
        .then(({ data }) => {
          if (data && data.existAnyDisbursement) {
            this.$router.push({
              name: 'student-reinbursement',
              params: {
                tapRequestId: this.data.id,
                requestId: data.disbursementRequestInNewId,
              },
            });
          } else {
            // Request SSN
            this.$emit('on-ssn-open-close', this.index);
          }
        })
        .catch((err) =>
          this.ShowToast(
            'Alerta',
            err.response.data.message || err.message,
            'error'
          )
        );
    },
    async requestHistory(tabIdx) {
      this.$emit('on-open-close', null);
      this.activeTab = tabIdx;
      this.openHistory = true;
      const { data } = await this.showHistoryRequest(this.data.requestNumber);
      this.historyRequestList = data.map((item) => ({
        ...item,
        isOpen: false,
        isReimbursementActive: false,
      }));
      this.$emit('on-open-close', this.index);
    },
    getDisbursementStatusColor(statusId) {
      return parseStateColorByDisbursementStatus(statusId);
    },
  },
};
</script>
<style lang="scss" scoped>
.custom-tab {
  --tab-active-color: #2db782 !important;
}
.spam-separator {
  margin-left: -2px;
}
</style>
