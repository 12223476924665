<template>
  <validation-observer ref="ssnRef">
    <div class="rounded mx-1 bg-secondary-3">
      <div class="rounded mx-2">
        <div class="row ml-3">
          <div class="col-12 col-md-3 pt-4">
            <validation-provider
              v-slot="{ errors }"
              :custom-messages="{
                required: 'El número de seguro social es requerido.',
                ssn: 'El número de seguro social no es válido.',
              }"
              rules="required|ssn"
            >
              <custom-input
                v-model="ssnNumber"
                :error="errors[0]"
                label="Número de Seguro Social:"
                label-class="font-14"
                mask-type="Personalssn"
                maxlength="11"
                placeholder="xxx-xx-xxxx"
              />
            </validation-provider>
          </div>
          <div class="col-12 col-md-8 pt-5 d-flex align-items-start mt-2">
            <action-button
              i="fas fa-times-circle"
              text="Cancelar"
              variant="outline-danger mr-3 btn-block-xs-only"
              @click="onCancel"
            />
            <action-button
              i="fas fa-arrow-circle-right"
              text="Continuar"
              variant="success pl-3 pr-3 btn-block-xs-only"
              @click="onContinue"
            />
            <div v-if="sifdeRegistryDocument" class="ml-4 mt-2">
              <a class="color-green" href="#" @click.prevent="downloadFile()">
                <i class="fas fa-file-download" />
                Descargar formulario de registro de SIFDE
              </a>
            </div>
          </div>
        </div>
        <div class="row mt-3 ml-3">
          <custom-error
            class="col-11 mt-2 mx-3"
            :is-alert-mode="true"
            message="Para comenzar con el proceso de solicitud de reembolso, es importante que se encuentre registrado en el Sistema de Información Financiera del Departamento de Educación (SIFDE)."
          />
        </div>
      </div>
    </div>
  </validation-observer>
</template>

<script>
import CustomInput from 'CustomInput';
import ActionButton from 'ActionButton';
import alert from '@quisit/mipe-utils/mixins/alert.mixin';
import CustomError from 'custom-error';
import {
  validateSsn,
  getSIFDERegistrationDocument,
} from '@/services/api/disbursement.api.js';
import { downloadBlobToFile } from '@/utils/blob';

export default {
  name: 'RequestReimbursement',
  components: {
    CustomInput,
    ActionButton,
    CustomError,
  },
  mixins: [alert],
  props: {
    tapRequestId: {
      type: Number,
      default: null,
    },
    academicYearId: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
    ssnNumber: null,
    sifdeRegistryDocument: null,
  }),
  async mounted() {
    await getSIFDERegistrationDocument().then(
      (res) => (this.sifdeRegistryDocument = res.data)
    );
  },
  methods: {
    async onContinue() {
      const isValid = await this.$refs.ssnRef.validate();
      if (!isValid) {
        return;
      }

      const { data } = await validateSsn(this.ssnNumber).catch((err) => {
        this.ShowToast(
          'Alerta',
          err.response.data.message || err.message,
          'error'
        );
        return;
      });
      this.$emit('on-continue', {
        ssnValid: data,
        ssn: this.ssnNumber,
      });
    },
    onCancel() {
      this.ssnNumber = null;
      this.$emit('on-cancel');
    },
    downloadFile() {
      if (this.sifdeRegistryDocument?.content) {
        downloadBlobToFile(this.sifdeRegistryDocument);
      }
    },
  },
};
</script>
